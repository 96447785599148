import { useEffect, useState } from 'react';
import * as Ads from '@smd/advertising';
import { useDebounce } from 'react-use';

export const useSearchFilterAdsRefresh = (
  initialValue: string | undefined,
  value: string
) => {
  const [initialRender, setInitialRender] = useState(true);

  useDebounce(
    () => {
      !initialRender && Ads.Utilities.refresh();
    },
    500,
    [value]
  );

  useEffect(() => {
    initialValue !== value && setInitialRender(false);
  }, [initialValue, value]);
};
