import React, { useEffect, useState } from 'react';
import styles from './FreeTextSearchFilter.module.scss';
import TextField from '@/atoms/TextField';
import { useDebounce } from 'react-use';
import LargeCloseIcon from '@/icons/LargeCloseIcon';
import SearchIcon from '@/icons/SearchIcon';
import { useQuery } from '@tanstack/react-query';
import { getSearchSuggestions } from 'api/search';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { Item } from '@/atoms/Dropdown';
import { useFloatingList } from '@/hooks/useFloatingList';
import { useSrpView } from '@/hooks/useSrpView';
import { useSearchFilterAdsRefresh } from '../../../advertising/useSearchFilterAdsRefresh';

type Props = {
  id: string;
  value?: string;
  onChange: (val: string | undefined) => void;
  containerRef?: React.MutableRefObject<HTMLElement | null>;
};

export const FreeTextSearchFilter = ({
  id,
  value: initialValue,
  onChange,
  containerRef,
}: Props) => {
  const [value, setValue] = React.useState(initialValue || '');
  const [debouncedValue, setDebouncedValue] = React.useState('');
  const [placeholder, setPlaceholder] = React.useState('Søg på bil');
  const closeIconRef = React.useRef<HTMLButtonElement>(null);
  const { toggleSrpViewTracking: trackView, newSrpView } = useSrpView();

  const toggleSrpViewTracking = id === 'SearchSRP' ? trackView : undefined; // only track after actions on the free text search field on the SRP
  useSearchFilterAdsRefresh(initialValue, value);

  useDebounce(
    () => {
      // Values did not change
      if (initialValue === value) return;
      onChange(value);
      setDebouncedValue(value);
      if (value === '') {
        // always trigger view tracking when clearing the input field, since it automatically means a change in the search request;
        // this applies for both pressing X in the field and for pressing Escape while the field is focused
        toggleSrpViewTracking && toggleSrpViewTracking(true);
      }
    },
    400,
    [value]
  );

  const { data } = useQuery({
    queryKey: ['search-suggestions', debouncedValue],
    queryFn: async ({ signal }) => getSearchSuggestions(debouncedValue, signal),
    staleTime: 5 * 60 * 1000, // 5 mins
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!debouncedValue,
    keepPreviousData: !!value,
  });

  const {
    x,
    y,
    strategy,
    refs,
    context,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    activeIndex,
    setActiveIndex,
    setOpen,
    open,
    listRef,
  } = useFloatingList(data, true);

  React.useEffect(() => {
    if (typeof initialValue !== 'undefined' && initialValue !== value) {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) =>
    setValue(e.currentTarget.value);

  const handleOnBlur = (e: any) => {
    if (e.relatedTarget) {
      const clickReceiver = listRef.current?.find(
        (node) => node === e.relatedTarget
      );
      // if the blur is because of a click on the X icon or because of the click on a suggestion, don't track the view;
      // these cases are handled separately, closer to the search update -> making sure we always track with the latest search values
      if (e.relatedTarget === closeIconRef.current || !!clickReceiver) {
        return;
      }
    }

    toggleSrpViewTracking && toggleSrpViewTracking(newSrpView); // we only want to track on blur when the input value has changed/a new search has been made; otherwise, don't track a view
  };

  const clear = () => {
    setValue('');
  };

  React.useEffect(() => {
    const { matches } = window.matchMedia('(min-width: 600px)');
    setPlaceholder(!matches ? 'Søg på bil' : 'Søg på bil, mærke, model...');
  }, []);

  return (
    <>
      <TextField
        id={id}
        data-e2e="freetext-search"
        type="search"
        value={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        startElement={
          <SearchIcon
            style={{
              marginLeft: 'var(--padding-inner-sm)',
              color: 'var(--color-grey-2)',
              display: 'inline-flex',
              flex: 'none',
              width: 'auto',
            }}
          />
        }
        endElement={
          !!value && (
            <button
              onClick={clear}
              className={styles.resetButton}
              ref={closeIconRef}
            >
              <LargeCloseIcon height={16} width={16} />
            </button>
          )
        }
        placeholder={placeholder}
        aria-autocomplete="list"
        wrapperProps={{
          ...getReferenceProps({
            ref: refs.setReference,
            className: styles.search,
            onKeyDown(event) {
              if (event.key === 'Enter') {
                if (activeIndex != null && data?.[activeIndex]) {
                  onChange(data[activeIndex].name!);
                  setActiveIndex(null);
                  refs.domReference.current?.focus();
                }
                setOpen(false);
                // always trigger view tracking when selecting (enter-press) a suggestion or pressing enter after writing in the field,
                // since it automatically means the user has decided & the search request is settled
                toggleSrpViewTracking && toggleSrpViewTracking(true);
              }
            },
          }),
        }}
      />
      {data && data.length > 0 && (
        <FloatingPortal root={containerRef}>
          {open && (
            <FloatingFocusManager
              context={context}
              initialFocus={-1}
              visuallyHiddenDismiss
            >
              <div
                {...getFloatingProps({
                  ref: refs.setFloating,
                  className: styles.popover,
                  style: {
                    position: strategy,
                    left: x ?? 0,
                    top: y ?? 0,
                  },
                })}
              >
                {data &&
                  data.length > 0 &&
                  data.map((suggestion: any, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <Item
                      {...getItemProps({
                        key: suggestion.name,
                        ref(node) {
                          listRef.current[index] = node;
                        },
                        tabIndex: index === activeIndex ? 0 : -1,
                        onClick() {
                          onChange(suggestion.name!);
                          setActiveIndex(null);
                          setOpen(false);
                          refs.domReference.current?.focus();
                          // always trigger view tracking when clicking on a suggestion,
                          // since it automatically means the user has decided & the search request is settled
                          toggleSrpViewTracking && toggleSrpViewTracking(true);
                        },
                      })}
                      active={activeIndex === index}
                    >
                      {suggestion.name}
                    </Item>
                  ))}
              </div>
            </FloatingFocusManager>
          )}
        </FloatingPortal>
      )}
    </>
  );
};
