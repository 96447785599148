import React from 'react';
import Router from 'next/router';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useImmerReducer } from 'use-immer';
import { getSearchResultsBySearchRequest } from '../api/search';
import {
  flattenFilters,
  flattenOptionValues,
  countActiveFilters,
  deepComparison,
  formatNumber,
} from '@/utils';
import { useDebouncedState } from './useDebouncedState';
import { searchRequestReducer } from './SearchRequestReducer';
import {
  SmdBasSrpModelsFilterOption,
  SmdBasSrpModelsFilterOptionValue,
  SmdBasSrpModelsSearchRequest,
  SmdBasSrpModelsSelectedFilterValue,
} from 'types/api';
import { useSrpPulseClickTracking } from './useSrpPulseClickTracking';
import {ExperimentVariant, makeExperimentObject} from "@/utils/amplitudeExperiments";

export const useSearch = (initial: SmdBasSrpModelsSearchRequest, experiments?: ExperimentVariant[]) => {
  const queryClient = useQueryClient();
  !!experiments && makeExperimentObject(experiments).ValereTest === 'relevance' && !initial.selectedSorting && (initial.selectedSorting = {sortBy: 'relevance', sortOrder: 'asc'})
  const [searchRequest, dispatch] = useImmerReducer(
    searchRequestReducer,
    initial
  ); // First state update (when user changes value in frontend)
  const { debouncedValue: debouncedSearchRequest, isActive: isDebounceActive } =
    useDebouncedState(searchRequest, 500); // Second state update (when debounce is done)

  const { isFetching, data, error } = useQuery({
    // Third state update
    queryKey: ['search', debouncedSearchRequest],
    queryFn: async ({ signal }) =>
      getSearchResultsBySearchRequest({ ...debouncedSearchRequest }, signal),
    staleTime: 20000,
    refetchOnWindowFocus: false,
    retry: false,
    keepPreviousData: true, // Needs to be true, as we build the frontend from the data
  });

  const { toggleSrpFilterSetTracking, toggleSrpFilterToggleTracking } =
    useSrpPulseClickTracking(
      isFetching,
      isDebounceActive,
      data?.searchRequest,
      searchRequest,
      data?.tracking
    );
  const location = data?.location;

  React.useEffect(() => {
    if (
      !isDebounceActive &&
      !isFetching &&
      data?.searchRequest &&
      // we need this check to avoid extra "re-renders" of this useEffect and of all the useEffects using isDebounceActive & isFetching (fx. the useEffect handling tracking of SRP views),
      // since the re-rendering of this useEffect will trigger their values to be changed in the process
      !deepComparison(data?.searchRequest, searchRequest)
    ) {
      // this case usually happens when the user selects makes (or models) in an order that is different from
      // the order the backend returns;
      // or if the backend corrects the client searchRequest in some manner (fx. non-existing car type/make combinations)
      queryClient.setQueryData(['search', data.searchRequest], data);
      dispatch({
        type: 'SetSearchRequest',
        payload: data.searchRequest,
      });
    }
    // Disabled, as we do not want to run this when the searchRequest changes (e.g. a UI interaction - only when the searchRequest from the backend changes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    data?.searchRequest,
    data?.tracking,
    isDebounceActive,
    isFetching,
  ]);

  // TODO: Consider if this useEffect should be merged with the one above - we never change location client-side
  React.useEffect(() => {
    if (!!location) {
      const url = new URL(location);

      if (url.href === window.location.href) return;

      Router.replace(url.href.split('www.bilbasen.dk')[1], undefined, {
        shallow: true,
      } as any);
    }
  }, [location]);

  /**
   * All filters unnested without groupings
   */
  const flattenedFilters = React.useMemo(
    () => flattenFilters(data?.filterOptions as SmdBasSrpModelsFilterOption[]), // TODO: Verify that it is not null
    [data?.filterOptions]
  );

  const defaultsForKey = React.useMemo(
    () =>
      flattenedFilters.reduce((acc, filter) => {
        if (!filter.key) return acc;

        switch (filter.type) {
          case 'SingleSelection': {
            const matches = filter.optionValues?.filter((ov) => ov.isDefault);
            if (matches && matches.length > 1) {
              throw new Error(
                'SingleSelection filters cannot have multiple default values'
              );
            }

            if (matches && matches.length === 1) {
              acc[filter.key] = matches[0].value;
            }
            break;
          }
          case 'MultiSelection': {
            const matches = filter.optionValues
              ?.filter((ov) => ov.isDefault)
              .map((ov) => ov.value);

            if (!!matches && matches.length > 0) {
              acc[filter.key] = matches;
            }
            break;
          }
          case 'Text': {
            acc[filter.key] = '';
            break;
          }
          case 'Numeric': {
            acc[filter.key] = undefined;
            break;
          }
          case 'NumericDouble': {
            acc[filter.key] = undefined;
            break;
          }
          case 'Boolean': {
            acc[filter.key] = false;
            break;
          }
          case 'Range': {
            acc[filter.key] = {
              fromValue: undefined,
              toValue: undefined,
            };
          }
        }

        return acc;
      }, {} as Record<string, any>),
    [flattenedFilters]
  );

  /**
   * Returns meta data about a specific filter e.g. title, options and more
   */
  const getFilterMeta = React.useCallback(
    (key: string, renderType?: string) => {
      if (!!renderType) {
        return flattenedFilters.find(
          (f) => f.key === key && f.renderType === renderType
        );
      }
      return flattenedFilters.find((f) => f.key === key);
    },
    [flattenedFilters]
  );

  const getFiltersKeys = React.useCallback(() => {
    return flattenedFilters.map((f) => f.key).filter(Boolean) as string[];
  }, [flattenedFilters]);

  /**
   * Sets the value of the filter with the specified key (uses the meta info to determine type of value);
   * id used to differentiate between filters/fields with the same key
   */
  const setValue = React.useCallback(
    (key: string, value: any, id?: any) => {
      const filterMeta = getFilterMeta(key);

      if (!filterMeta) {
        console.warn('Attempt to set value on unknown filter with key: ', key);
        return;
      }

      toggleSrpFilterSetTracking(true, { id, key, value });

      // When setting Ownership or BuyerType, we need to reset all other filters
      if (['Ownership', 'BuyerType'].includes(key)) {
        const {
          PriceType,
          Category,
          ...remainingFilters
        } = searchRequest.selectedFilters ?? {};

        if (key === 'Ownership') {
          dispatch({
            type: 'SetSearchRequest',
            payload: {
              selectedFilters: {
                ...remainingFilters,
                Ownership: { value: { value } },
              },
              selectedSorting: searchRequest.selectedSorting,
            },
          });
          return;
        }

        if (key === 'BuyerType') {
          dispatch({
            type: 'SetSearchRequest',
            payload: {
              selectedFilters: {
                ...remainingFilters,
                BuyerType: { value: { value } },
              },
              selectedSorting: searchRequest.selectedSorting,
            },
          });
          return;
        }
      }

      // TODO: Validate that value conforms to expected type here?
      switch (filterMeta.type) {
        case 'Boolean':
          dispatch({ type: 'SetBooleanValue', payload: { key, value } });
          break;
        case 'Text':
          dispatch({ type: 'SetTextValue', payload: { key, value } });
          break;
        case 'Numeric':
          dispatch({ type: 'SetNumericValue', payload: { key, value } });
          break;
        case 'NumericDouble':
          dispatch({
            type: 'SetNumericDoubleValue',
            payload: {
              key,
              firstValue: value?.firstValue,
              secondValue: value?.secondValue,
            },
          });
          break;
        case 'Range':
          dispatch({
            type: 'SetRangeValue',
            payload: {
              key,
              fromValue: value.fromValue,
              toValue: value.toValue,
            },
          });
          break;
        case 'SingleSelection':
          dispatch({ type: 'SetSingleSelectValue', payload: { key, value } });
          break;
        case 'MultiSelection':
          dispatch({
            type: 'SetMultiSelectionValue',
            payload: { key, values: value },
          });
          break;
        case 'NestedMultiSelection':
          dispatch({
            type: 'SetNestedMultiSelectionValue',
            payload: { key, values: value },
          });
          break;
        default:
          console.warn(
            `setValue: Unable to set value in filter ${key} of type ${filterMeta.type}`
          );
      }
    },
    [dispatch, getFilterMeta]
  );

  /**
   * Toggles hee value of the filter with the specified key e.g. if the value does not exists, adds it - if it does removes it
   */
  const toggleValue = React.useCallback(
    (key: string, value: any) => {
      const filterMeta = getFilterMeta(key);

      if (typeof value === 'undefined') {
        console.warn(
          `toggleValue: No value provided to toggle for key: ${key}`
        );
        return;
      }

      if (!filterMeta) {
        console.warn(
          'Attempt to toggle value on unknown filter with key: ',
          key
        );
        return;
      }

      if (filterMeta.type === 'NestedMultiSelection') {
        toggleSrpFilterToggleTracking(true, {
          key,
          value: `${value.parent.value}, ${value.value}`,
        }); // for tracking the model filter, the pulse event.name is formatted as "<Make>, <Model>"
      } else {
        toggleSrpFilterToggleTracking(true, { key, value });
      }

      // TODO: Validate that value conforms to expected type here?
      switch (filterMeta.type) {
        case 'Boolean':
          dispatch({ type: 'ToggleBooleanValue', payload: { key } });
          break;
        case 'MultiSelection':
          dispatch({
            type: 'ToggleMultiSelectionValue',
            payload: { key, value },
          });
          break;
        case 'NestedMultiSelection':
          dispatch({
            type: 'ToggleNestedMultiSelectionValue',
            payload: { key, parent: value.parent, value: value.value },
          });
          break;
        default:
          console.warn(
            `toggleValue: Unable to toggle value in filter ${key} of type ${filterMeta.type}`
          );
      }
    },
    [dispatch, getFilterMeta, toggleSrpFilterToggleTracking]
  );

  /**
   * Get the raw value of a filter
   */
  const getValue: any = React.useCallback(
    (key: string, renderType?: string) => {
      const filterMeta = getFilterMeta(key, renderType);
      const currentFilterValue = searchRequest.selectedFilters?.[key];

      if (!currentFilterValue || !filterMeta) return undefined;

      switch (filterMeta.type) {
        case 'SingleSelection':
          return currentFilterValue?.value?.value;
        case 'NestedMultiSelection':
        case 'MultiSelection':
          return currentFilterValue?.values;
        case 'Range':
          return currentFilterValue?.value;
        case 'Text':
          return currentFilterValue?.value?.textValue;
        case 'Numeric':
          return currentFilterValue?.value?.numericValue;
        case 'NumericDouble':
          return currentFilterValue?.value;
        case 'Boolean':
          return currentFilterValue?.value?.booleanValue;
        default:
          return undefined;
      }
    },
    [getFilterMeta, searchRequest.selectedFilters]
  );

  const getLabelValue = React.useCallback(
    (
      key: string,
      value: string | number | SmdBasSrpModelsSelectedFilterValue
    ) => {
      const meta = getFilterMeta(key);
      if (!meta) return 'Ukendt';

      let labelValue = '';

      switch (meta.type) {
        case 'SingleSelection':
        case 'MultiSelection':
          labelValue =
            meta.optionValues?.find((f) => f.value === value)?.name ?? 'Ukendt';
          break;
        case 'NestedMultiSelection':
          labelValue =
            meta.dependantOptionValues
              ?.flatMap(
                (depOption) =>
                  flattenOptionValues(
                    depOption.optionValues as SmdBasSrpModelsFilterOptionValue[]
                  ) // TODO: Remove this type cast by validating the value or updating the API
              )
              ?.find((option) => option.value === value)?.name ?? 'Ukendt';
          break;
        case 'Range':
          const fromValue = (value as SmdBasSrpModelsSelectedFilterValue)
            ?.fromValue;
          const toValue = (value as SmdBasSrpModelsSelectedFilterValue)
            ?.toValue;
          if (typeof fromValue === 'number' && typeof toValue === 'number') {
            labelValue = `${
              meta.unit === 'år' ? fromValue : formatNumber(fromValue)
            } - ${meta.unit === 'år' ? toValue : formatNumber(toValue)}`;
          } else {
            labelValue =
              typeof fromValue === 'number'
                ? `Fra: ${
                    meta.unit === 'år' ? fromValue : formatNumber(fromValue)
                  }`
                : typeof toValue === 'number'
                ? `Til: ${meta.unit === 'år' ? toValue : formatNumber(toValue)}`
                : 'Ukendt';
          }
          break;
        case 'Text':
        case 'Numeric':
          labelValue = `${value}` ?? 'Ukendt';
          break;
        case 'NumericDouble':
          if (key === 'GeoLocation') break;
          const firstValue = (value as SmdBasSrpModelsSelectedFilterValue)?.firstValue;
          const secondValue = (value as SmdBasSrpModelsSelectedFilterValue)?.secondValue;
          labelValue = `${firstValue} - ${secondValue}` ?? 'Ukendt';
          break;
        case 'Boolean':
          labelValue = value ? meta.title ?? 'Ukendt' : '';
          break;
        default:
          labelValue = 'Ukendt';
          break;
      }

      // It looks strange if the label is "1982 år"
      if (meta.unit && meta.unit !== 'år') {
        labelValue = `${labelValue} ${meta.unit}`;
      }
      return labelValue;
    },
    [getFilterMeta]
  );

  const getFiltersOptionValues = React.useCallback(
    (key: string) => {
      const filter = getFilterMeta(key);
      if (!filter) return [];

      switch (filter.type) {
        case 'MultiSelection':
          return filter.optionValues?.filter(Boolean).map((f) => f.value) ?? [];
        case 'NestedMultiSelection':
          return (
            filter.dependantOptionValues
              ?.flatMap(
                (depOption) =>
                  flattenOptionValues(
                    depOption.optionValues as SmdBasSrpModelsFilterOptionValue[]
                  ) // TODO: Remove this type cast by validating the value or updating the API
              )
              ?.filter(Boolean)
              .map((f) => f.value) ?? []
          );
        default:
          return [];
      }
    },
    [getFilterMeta]
  );

  /**
   * Checks if it is possible for the filter to be completely deselected / unchecked / empty, without any default values being set
   * @param key Filter to check
   */
  const canBeEmpty = React.useCallback(
    (
      key: string,
      value?: string | number | SmdBasSrpModelsSelectedFilterValue
    ) => {
      const filter = getFilterMeta(key);
      if (!filter) return false;
      if (filter.type === 'SingleSelection') {
        const hasDefaultValue = filter.optionValues?.find((ov) => ov.isDefault);
        if (hasDefaultValue) {
          return false;
        }
      }
      return true;
    },
    [getFilterMeta]
  );

  /**
   * Clears the filter
   * @param key Filter to clear (if left empty, clear all filters)
   */
  const clear = (
    key?: string,
    value?: string | number | SmdBasSrpModelsSelectedFilterValue
  ) => {
    if (!!key) {
      const filter = getFilterMeta(key);
      if (!filter) return;
      if (
        typeof value !== 'undefined' &&
        (filter.type === 'MultiSelection' ||
          filter.type === 'NestedMultiSelection')
      ) {
        toggleValue(key, value);
      } else {
        setValue(key, defaultsForKey[key]);
      }
    } else {
      // the order of these dispatches is important for when clearing all the chips on the SRP (Nulstil); it should dispatch in the order of the filters
      dispatch({
        type: 'SetSearchRequest',
        payload: {
          selectedFilters: {
            BuyerType: {
              value: {
                value:
                  searchRequest.selectedFilters?.['BuyerType']?.value?.value ??
                  'Private',
              },
            },
            Ownership: {
              value: {
                value:
                  searchRequest.selectedFilters?.['Ownership']?.value?.value ??
                  'Retail',
              },
            },
            GeoLocation: {
              value:
                searchRequest.selectedFilters?.['GeoLocation']?.value ??
                  { firstValue: undefined, secondValue: undefined },
            }
          },
          selectedSorting: searchRequest.selectedSorting,
        },
      });
    }
  };

  /**
   * Sets the sort type and direction
   */
  const setSort = React.useCallback(
    (sortBy: string, sortOrder: string) =>
      dispatch({ type: 'SetSorting', payload: { sortBy, sortOrder } }),
    [dispatch]
  );

  return {
    getValue,
    getLabelValue,
    setValue,
    toggleValue,
    setSort,
    clear,
    canBeEmpty,
    isFetching, // : isFetching || isDebounceActive, // TODO: We should indicate loading when the debounce start, and lower it once isFetching is done - unfortunately these are both false for a short period after ending the debounce and starting a new fetch
    isDebounceActive,
    error,
    data,
    searchRequest,
    getFiltersKeys,
    getFiltersOptionValues,
    selectedCount: countActiveFilters(data?.filterOptions ?? [], searchRequest),
  };
};
